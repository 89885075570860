.tllTVConfig {
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
}

.tllModelInfoItem {
  /* display: flex;
  gap: 20px;
  width: 300px; */
}
.tillModelInfoItemName {
  /* vertical-align: middle; */
}
/* TV Configuration */
.tllSectionTitle {
  font-size: 18px;
  font-weight: bold;
}

.tllSection {
  margin: 5px;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  vertical-align: top;
  border: 1px solid #dedede;
  background: #fff;
  border-radius: 8px;
  margin: 0 9px 10px;
  padding: 5px 30px;
}

.tllSection ul {
  list-style: none;
  padding: 0px;
  margin: 0%;
}
.tllSection li {
  font-size: 13px;
  margin: 4px 0;
  display: flex;
  align-items: center;
  gap: 20px;
}
.tllSection .disabled {
  color: lightgray;
  /* text-decoration: line-through; */
}
.tllSectionInput {
  min-width: 100px;
  height: 35px;
  /* background-color: #efefef; */
  border-radius: 20px;
  border: 2px solid gray;
  margin: 1px 5px;
  padding: 2px 0;
  text-align: center;
}
.tllSectionInput:disabled {
  border: none;
}
.tllSectionButton {
  border: none;
  min-width: 100px;
  height: 35px;
  background-color: #efefef;
  border-radius: 20px;
  margin: 1px 5px;
  padding: 2px 0;
  text-align: center;
}
.tllSection li select {
  width: 200px;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 17px) calc(1em + 2px),
    calc(100% - 12px) calc(1em + 2px);
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
}
.tllSectionInput:hover:enabled {
  border: 2px solid #C70851;
}
.tllSectionInput:focus  {
  outline: none !important;
  border: 2px solid #C70851;
}
.tllSectionButton:hover:enabled {
  background-color: #ffcccb;
  cursor: pointer;
}
.tllSection ul .secondLevel {
  margin-left: 30px;
}