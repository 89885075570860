.tllConverter {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* main */
.tllMain {
  display: flex;
  margin: 0;
}

/* Side menu */

.tllSidenav {
  height: calc(100vh - 56px);
  width: 200px;
  border-right: 1px solid #d0d1d2;
  /* background-color: #f8f8f8; */
  color: gray;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  /* padding-top: 1px; */
}
.tllSidenav input {
  display: none;
}
.tllSidenav form label {
  padding: 8px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  display: block;
  height: 40px;
  border-bottom: 1px solid #e8e8e8;
  background-color: #f8f8f8;
}
.tllSidenav button {
  padding: 12px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border: 0;
  width: 100%;
  height: 44px;
  /* color: #383838;
  background-color: white; */
  background-color: #f8f8f8;
  color: gray;
  display: block;
  border-bottom: 1px solid #e8e8e8;
}
.tllSidenav button:hover {
  /* background: #e4e4e4; */
  background: #ffcccb;
  cursor: pointer;
  color: black;
}
.tllSidenav label:hover{
  /* background: #e4e4e4; */
  background: #ffcccb;
  cursor: pointer;
  color: black;
}
.author {
  padding: 10px;
  margin-bottom: 10px;
  font-size: 10px;
  text-align: center;
  margin: 0;
}
.author p {
  margin: 0;
}

/* TLL content */
.tllContent {
  height: calc(100vh - 56px);
  width: 100%;
  /* margin: 0 10px; */
  padding: 10px;
  background-color: #f2f2f2;
  overflow-y: auto;
}

/* Channel editor */
.tllChannelEditor {
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
}
.tllChannelEditorSection {
  height: 100%;
  gap: 40px;
  list-style-type: none;
  vertical-align: top;
  border: 1px solid #dedede;
  background: #fff;
  border-radius: 8px;
  margin: 0 9px;
  padding: 5px 20px;
}
.tllChToolbox {
  display: flex;
  justify-content: left;
  margin: 5px 0px;
}
.tllChToolbox * {
  padding: 0px 20px;
  border: none;
  background-color: #efefef;
  font-size: 13px;
  border-radius: 20px;
  cursor: pointer;
}
.tllChToolbox *:hover {
  background: #ffcccb;
}
.tllChToolbox input {
  width: 200px;
  height: 30px;
  margin: 0 10px;
  cursor: text;
}
.tllChEditorContainer {
  height: calc(100% - 55px);
}
.ag-theme-balham {
  --ag-balham-active-color: #C70851;
  --ag-input-focus-border-color: #C70851;
  --ag-selected-row-background-color: #ffcccb;
}