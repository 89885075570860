.App {
  /* text-align: center; */
  min-height: 100vh;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loginPage {
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loginLogo {
  width: 300px;
}
.loginTitle {
  font-size: 16px;
  font-style: bold;
  margin: 5px;
  text-align: center;
}
.loginItem {
  margin: 15px;
  /* padding: 5px; */
}
.loginKey {
  display: inline-block;
  width: 100px;
  font-size: 15px;
}
.loginValue {
  /* height: 20px; */
  font-size: 15px;
  padding: 10px;
  border: 1px solid #abadb3;
  border-radius: 5px;
  width: 250px;
}
.loginButton {
  text-align: center;
}
.loginButton2 {
  font-size: 15px;
  padding: 10px;
  width: 200px;
  border: 1px solid #abadb3;
  border-radius: 30px;
}
.loginButton2:hover {
  background-color: lightgray;
  cursor: pointer;
}
