/* Title bar */
.tllTitleBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 56px;
  width: 100%;
  box-shadow: 0 0px 4px rgb(0 0 0 / 20%);
  background-color: rgb(255, 255, 255);
  z-index: 2;
}

.titleLogo {
  padding: 0 20px;
  display: flex;
  align-items: center;
}
.titleText {
  padding: 0 10px;
  font-size: 20px;
  font-weight: bold;
}
.titleLogoImg {
  height: 18px;
}
.titleVersion {
  font-size: medium;
} 
.titleFileInfo{
  font-size: small;
} 
.titleButtons {
  display: flex;
}
.titleButtons button {
  /* display: inline-flex; */
  justify-content: space-evenly;
  align-items: center;
  height: 40px;
  width: 150px;
  margin: 10px 24px;
  border: 0;
  border-radius: 20px;
  color: #383838;
  cursor: pointer;
  font-size: 15px;
}
.titleButtons button:hover {
  /* background: #e4e4e4; */
  background: #ffcccb;
}
.titleButtons .titleLogout {
  display: inline-flex;
  height: 40px;
}
.titleButtons .titleLogout::before {
  content: "";
  background-image: url("../../public/icon_user.png");
  height: 20px;
  width: 20px;
}
